import React from "react";
import Countdown, { CountdownRenderProps, zeroPad } from "react-countdown";
import title_left_decor from "../images/decors/title_left_decor_2.png";
import title_right_decor from "../images/decors/title_right_decor_2.png";
import SaleLink from "./SaleLink";
import startSale from "./StartSale.module.scss";

export enum EStartSaleType
{
	DEFAULT = 0,
	MAIN = 1,
}

function getClassName(type: EStartSaleType)
{
	switch (type) {
		case EStartSaleType.DEFAULT:
			return startSale.container;
		case EStartSaleType.MAIN:
			return startSale.container_main;
	}
}

const renderer = ({days, hours, minutes, seconds, completed}: CountdownRenderProps) => {
	if (completed) {
		return <SaleLink/>;
	} else {
		const showDays = days > 0;
		return (
			<div className={startSale.timer_container}>
				<h1 className={startSale.title}>Launch of the Tribal Books'</h1>
				<div className={startSale.title_with_decor}>
					<img src={title_left_decor} alt="left decor"/>
					<h1>Assets Sale</h1>
					<img src={title_right_decor} alt="right decor"/>
				</div>
				<div className={startSale.timer_box}>
					{showDays && <>
						<div className={startSale.big}>{days}</div>
						<div className={startSale.little}>days</div>
					</>
					}
					<div className={startSale.big}>{zeroPad(hours)}</div>
					<div className={startSale.little}>hour</div>
					<div className={startSale.big}>{zeroPad(minutes)}</div>
					<div className={startSale.little}>min</div>
					{!showDays && <>
						<div className={startSale.big_fixed}>{zeroPad(seconds)}</div>
						<div className={startSale.little}>sec</div>
					</>}
				</div>
			</div>
		);
	}
};

const StartSaleView = ({type}: {type: EStartSaleType}) => {
	const className = getClassName(type);

	let date = Date.parse("2020-11-11T17:00:00.000Z");
	if (typeof window !== `undefined` && window.location.href.includes("state=ready"))
		date = Date.now() - 1000;

	return (
		<div className={className}>
			<Countdown
				date={date}
				renderer={renderer}
			/>
		</div>
	);
};

export default StartSaleView;
