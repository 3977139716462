import { navigate } from "gatsby";
import React from "react";
import Countdown, { CountdownRenderProps, zeroPad } from "react-countdown";
import BannerImg from "../images/burn_packs.png";
import title_left_decor from "../images/decors/title_left_decor_2.png";
import title_right_decor from "../images/decors/title_right_decor_2.png";
import Button from "./Button";
import endSaleStyles from "./EndSaleView.module.scss";
import startSale from "./StartSale.module.scss";
import { EStartSaleType } from "./StartSaleView";

export const END_SALE_DATE = Date.parse("2020-12-04T15:00:00.000Z");
// export const END_SALE_DATE = Date.now() + 5000;
// export const END_SALE_DATE = Date.now() - 1000;

export function isSaleOver()
{
	return END_SALE_DATE < Date.now();
}

function getClassName(type: EStartSaleType)
{
	switch (type) {
		case EStartSaleType.DEFAULT:
			return startSale.container;
		case EStartSaleType.MAIN:
			return startSale.container_main;
	}
}

const renderer = ({days, hours, minutes, seconds, completed}: CountdownRenderProps) => {
	if (completed) {
		return null;
	} else {
		const showDays = days > 0;
		return (
			<div className={startSale.container}>
				<div className={startSale.content_sale}>
					<img className={startSale.decor} src={title_left_decor} alt="left decor"/>
					<div className={startSale.btn_content}>
						<img className={endSaleStyles.end_sale_banner} src={BannerImg} alt="banner"/>

						<div className={startSale.timer_box}>
							{showDays && <>
								<div className={startSale.big}>{days}</div>
								<div className={startSale.little}>days</div>
							</>
							}
							<div className={startSale.big}>{zeroPad(hours)}</div>
							<div className={startSale.little}>hour</div>
							<div className={startSale.big}>{zeroPad(minutes)}</div>
							<div className={startSale.little}>min</div>
							{!showDays && <>
								<div className={startSale.big_fixed}>{zeroPad(seconds)}</div>
								<div className={startSale.little}>sec</div>
							</>}
						</div>

						<Button text="Buy packs" onClick={() => navigate("/buy-pack")}/>
					</div>
					<img className={startSale.decor} src={title_right_decor} alt="right decor"/>
				</div>
			</div>
		);
	}
};

const EndSaleView = ({type}: {type: EStartSaleType}) => {
	const className = getClassName(type);
	let date = END_SALE_DATE;
	if (typeof window !== `undefined` && window.location.href.includes("state=ready"))
		date = Date.now() - 1000;

	return (
		<div className={className}>
			<Countdown
				date={date}
				renderer={renderer}
			/>
		</div>
	);
};

export default EndSaleView;
